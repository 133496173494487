const firstCommentChangeIntervalMillis = 5e3, commentChangeIntervalMillis = 5e3, commentFadeTime = 1e3;comments = [{ stars: 5, 
    comment: "Danke dir f\xFCr die krasse pieces sehr gueti Qualit\xE4t sowie guete zuestand au priislich sehr fair.", 
    name: "", 
    "img-src": "https://cdn.shopify.com/s/files/1/0789/6213/1293/files/Kundenbewertung0.jpg?v=1720951089" }, 
    { stars: 4, 
    comment: "Sind fr\xFCndlich und schriebet schnell zrugg. Kleider hend gueti Qualit\xE4t und entspricht dem was mer sich vorstellt.", 
    name: "", 
    "img-src": "https://cdn.shopify.com/s/files/1/0789/6213/1293/files/Kundenbewertung2.jpg?v=1720951089" }, 
    { stars: 5, 
    comment: "Ich bin mega zfriede mit de sache. Oberteil und Hose sind beidi i guetem zustand gsi.", 
    name: "", 
    "img-src": "https://cdn.shopify.com/s/files/1/0789/6213/1293/files/Kundenbewertung3.jpg?v=1720951089" }, 
    { stars: 5, 
    comment: "Sitzt perfekt, danke vill mals!!", 
    name: "", 
    "img-src": "https://cdn.shopify.com/s/files/1/0789/6213/1293/files/Kundenbewertung_4.jpg?v=1732989778" }];var changeCommentTimer,loadCommentTimer,currentlyDisplayedComment=Math.floor(Math.random()*comments.length);loadComment();function setTextMoveBack(element,text){element.innerHTML=text,element.classList.remove("move-outside-left"),element.classList.remove("move-outside-right")}function getCommentTextsElement(){return document.querySelector(".customercomment .customercomment-texts")}function loadComment(index=currentlyDisplayedComment){let commentData=comments[index];console.log(index),document.querySelector(".customercomment-header").innerHTML="&starf;".repeat(commentData.stars),document.querySelector(".customercomment-comment").innerHTML="&laquo;&nbsp;"+commentData.comment+"&nbsp;&raquo;",document.querySelector(".customercomment-image img").src=commentData["img-src"],currentlyDisplayedComment=index}function slideCommentOut(){document.querySelector(".customercomment-texts").classList.add("move-outside-right"),document.querySelector(".customercomment-image").classList.add("move-outside-left")}function slideCommentIn(){document.querySelector(".customercomment-texts").classList.remove("move-outside-right"),document.querySelector(".customercomment-image").classList.remove("move-outside-left")}function changeComment(indexOffset=1){clearTimeout(changeCommentTimer),clearTimeout(loadCommentTimer),slideCommentOut(),currentlyDisplayedComment=(currentlyDisplayedComment+indexOffset+comments.length)%comments.length,loadCommentTimer=setTimeout(()=>{console.log("Load new"),loadComment(),slideCommentIn(),changeCommentTimer=setTimeout(changeComment,5e3)},1e3)}setTimeout(changeComment,5e3);